/*
 * @Author: zhangyu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-13 14:19:58
 */

export default [
  {
    path: 'roomSupplierMapping',
    name: 'roomSupplierMapping',
    meta: {
      title: '房间与设备供应商关系维护',
      permissionCode: 'cp-smartOS-roomSupplierMapping',
      routerType: 2,
    },
    component: () => import('../views/smart-room-supplier-mapping-manage/list/index.vue'),
  },
];
