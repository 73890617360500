/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-04-26 16:30:10
 */

import Router from 'vue-router';
import checkEvent from './check-event';
import group from './group';
import hotel from './hotel';
import room from './room';
import lockEvent from './lock-event';
import deviceType from './device-type';
import smartDeviceAuthorization from './device-authorizaiton';
import supplier from './supplier';
import roomSupplierMapping from './room-supplier-mapping';
import device from './device';

const RouterView = {
  template: '<div><router-view></router-view></div>',
};

const routes = [{
  path: '/smartOS',
  component: RouterView,
  children: [
    ...checkEvent,
    ...group,
    ...hotel,
    ...lockEvent,
    ...deviceType,
    ...smartDeviceAuthorization,
    ...supplier,
    ...room,
    ...roomSupplierMapping,
    ...device,
  ],
}];

export default new Router({
  mode: 'history',
  routes,
});
