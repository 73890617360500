/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-19 14:00:47
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-04-26 16:22:43
 */
import './set-public-path';
import Vue from 'vue';
import Vuex from 'vuex';
import AvocadoLoadVue from 'avocado-load-vue';
import has from 'lodash/has';
import forEach from 'lodash/forEach';
import HttpUtil from './utils/http/http-util-promise';
import packageConfig from '../package.json';
import router from './router';
import App from './App.vue';

import './assets/index.less';

Vue.config.productionTip = false;

// 添加Vuex支持
Vue.use(Vuex);
Vue.prototype.$httpUtil = HttpUtil;
window.$httpUtil = HttpUtil;

// 设置路由生命周期方法
if (has(Vue.prototype, '$routerHook')) {
  forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}

// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'views',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    router,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;
