/*
 * @Author: yanzhongliu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-14 16:41:57
 */

export default [
  {
    path: 'device',
    name: 'device',
    meta: {
      title: '设备列表',
      permissionCode: 'cp-smartOS-device',
      routerType: 2,
    },
    component: () => import('../views/smart-device-info-manage/list/index.vue'),
  },
  {
    path: 'device/info',
    name: 'deviceInfo',
    meta: {
      title: '设备详情',
      permissionCode: 'cp-smartOS-deviceInfo',
      routerType: 2,
    },
    component: () => import('../views/smart-device-info-manage/info/index.vue'),
  },
];
