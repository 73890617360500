/*
 * @Author: zhangyu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-05 21:12:08
 */

export default [
  {
    path: 'group',
    name: 'group',
    meta: {
      title: '集团管理',
      permissionCode: 'cp-smartOS-group',
      routerType: 2,
    },
    component: () => import('../views/smart-group-manage/list/index.vue'),
  },
];
