/*
 * @Author: yanzhongliu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-13 14:15:55
 */

export default [
  {
    path: 'deviceAuthorizaiton',
    name: 'deviceAuthorizaiton',
    meta: {
      title: '门锁授权事件查询',
      permissionCode: 'cp-smartOS-deviceAuthorizaiton',
      routerType: 2,
    },
    component: () => import('../views/smart-device-authorization/list/index.vue'),
  },
];
