/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-03-25 11:35:06
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-04-26 16:25:54
 */
const {
  showToast, fetch, download, urlToDownload,
} = window.$axios;

export default {
  showToast,
  fetch,
  download,
  urlToDownload,
};
