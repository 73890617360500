/*
 * @Author: jmfang
 * @Date: 2020-05-07 14:08:10
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-13 14:16:02
 */

export default [
  {
    path: 'deviceType',
    name: 'deviceType',
    meta: {
      title: '设备类型',
      permissionCode: 'cp-smartOS-deviceType',
      routerType: 2,
    },
    component: () => import('../views/smart-device-type-manager/list/index.vue'),
  },
];
