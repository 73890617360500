/*
* @Author: zhangyu
* @Date: 2020-07-17
*/
/*
 * @Author: zhangyu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-05 17:39:28
 */

export default [
  {
    path: 'hotel',
    name: 'hotel',
    meta: {
      title: '酒店管理',
      permissionCode: 'cp-smartOS-hotel',
      routerType: 2,
    },
    component: () => import('../views/smart-hotel-manage/list/index.vue'),
  },
];
