/*
 * @Author: jmfang
 * @Date: 2020-05-06 18:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-13 14:13:59
 */

export default [
  {
    path: 'lockEvent',
    name: 'lockEvent',
    meta: {
      title: '门锁事件查询',
      permissionCode: 'cp-smartOS-lockEvent',
      routerType: 2,
    },
    component: () => import('../views/smart-lock-event-manager/list/index.vue'),
  },
];
