/*
 * @Author: zhangyu
 * @Date: 2020-04-30 11:37:16
 * @Last Modified by: zhangyu
 * @Last Modified time: 2020-05-13 14:15:46
 */

export default [
  {
    path: 'checkEvent',
    name: 'checkEvent',
    meta: {
      title: '入离店事件查询',
      permissionCode: 'cp-smartOS-checkEvent',
      routerType: 2,
    },
    component: () => import('../views/smart-check-event-manage/list/index.vue'),
  },
];
